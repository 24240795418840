import { useRef } from 'react';

import { SectionHeading } from 'components/atomic/atoms';
import { VideoCardListMobile } from 'components/atomic/organism';
import { computeScroll } from 'helpers/computeScroll';

export default function EventGlimpsesMobile({ eventGlimpsesList = [] }) {
  const scrollRef = useRef(null);
  const progressBarRef = useRef(null);

  const handleScroll = () => {
    progressBarRef.current.style.width = computeScroll(scrollRef, 20);
  };

  const ProgressBar = () => (
    <div className='w-14 bg-dim-gray h-0.5 mx-auto mt-3 mb-4'>
      <div
        className={'bg-control h-0.5 w-1/5'}
        ref={progressBarRef}
      ></div>
    </div>
  );

  return (
    <section id='collection-section'>
      <div className='bg-white pt-4 pb-8 pr-3'>
        <div className='px-16'>
          <SectionHeading title={'A Glimpse into our Events'} />
        </div>
        <ProgressBar />
        <VideoCardListMobile
          {...{
            cardList: eventGlimpsesList,
            scrollRef,
            handleScroll
          }}
        />
      </div>
    </section>
  );
}
