import Image from 'next/image';

import { staticMediaStoreBaseURL } from 'config/common';

const aboutHaflaDescription = [
  {
    imageUrl: `${staticMediaStoreBaseURL}/icons/graphic-one.svg`,
    imgStyle: {
      height: 23,
      width: 60
    },
    contentLines: [
      'Hafla delivers an e-commerce-like,easy,fast,and',
      'trusted experience in an otherwise low-tech,high',
      'fragmentation,high friction industry'
    ],
    hrLineClass: ['about-delivery-hr z-20']
  },
  {
    imageUrl: `${staticMediaStoreBaseURL}/icons/graphic-two.svg`,
    imgStyle: {
      height: 26,
      width: 60
    },
    contentLines: [
      'Hafla is for events what Expedia is for travel-',
      'aggregating and digitizing a $1Tn+n industry'
    ],
    hrLineClass: ['about-event-start-hr', 'about-event-end-hr']
  },
  {
    imageUrl: `${staticMediaStoreBaseURL}/icons/graphic-three.svg`,
    imgStyle: {
      height: 26,
      width: 60
    },
    contentLines: [
      'Hafla minimizes the stress in event planning so',
      'that everyone can celebrate more'
    ],
    hrLineClass: ['about-event-planing-hr']
  }
];

const CommonTextSection = ({ imageUrl, imgStyle, contentLines = [] }) => (
  <div className='flex mr-0.5 '>
    <Image
      {...{
        src: imageUrl,
        ...imgStyle
      }}
      alt='About Hafla'
    />
    <ul className='mx-7'>
      {contentLines.map((item, index) => (
        <div
          className='font-google-basic text-section'
          key={index}
        >
          {item}
        </div>
      ))}
    </ul>
  </div>
);

const AboutDescription = () => (
  <div className='row-span-3 py-32 grid-flow-col gap-1 font-medium z-10'>
    {aboutHaflaDescription.map(
      ({ imageUrl, imgStyle, contentLines, hrLineClass }, index) => (
        <div key={index}>
          <CommonTextSection
            {...{
              imageUrl,
              contentLines,
              imgStyle
            }}
          />
          <div className='flex'>
            {hrLineClass.map((className, indexKey) => (
              <hr
                key={indexKey}
                className={className}
              />
            ))}
          </div>
        </div>
      )
    )}
  </div>
);

export default AboutDescription;
