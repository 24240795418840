const haflaServices = [
  {
    id: 1,
    image: '/why-choose-hafla-icons/price-guarantee.svg',
    name: 'Best Price Guarantee'
  },
  {
    id: 2,
    image: '/why-choose-hafla-icons/unlimited-supplies.svg',
    name: 'Unlimited Supplies'
  },
  {
    id: 3,
    image: '/why-choose-hafla-icons/trusted-quality.svg',
    name: 'Trusted Quality Partners'
  },
  {
    id: 4,
    image: '/why-choose-hafla-icons/customer-service.svg',
    name: 'Fast Customer Service'
  },
  {
    id: 5,
    image: '/why-choose-hafla-icons/quick-delivery.svg',
    name: 'Quick Delivery'
  }
];

export default haflaServices;
