import Image from 'next/image';

import { Heading } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';

const FixSizeLogo = ({ path, alt }) => (
  <Image
    width={90}
    height={45}
    src={`${staticMediaStoreBaseURL}${path}`}
    alt={alt}
    className='cursor-pointer w-full'
  />
);

export default function MobileHomeClientLogo() {
  return (
    <section
      div
      id='client-section'
    >
      <div className='bg-white pt-6 pb-7'>
        <Heading
          level={3}
          className='text-nero font-Inter font-bold text-2xl leading-6 text-center block'
        >
          We Love Our Clients
        </Heading>

        <div className='max-w-52 mx-auto w-full text-center pt-1.5 px-2'>
          <p className='text-nero font-Inter font-normal text-sm leading-4'>
            {' '}
            and they love us too!{' '}
          </p>
        </div>

        <div className='pt-3 pb-4'>
          <div className='w-9 h-0.5 bg-list mx-auto rounded'></div>
        </div>

        <div className='px-5 pt-3'>
          <div className='flex flex-wrap flex-row -mb-6 -px-4'>
            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{ path: '/logos/tiktok.png', alt: 'TikTok' }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{ path: '/logos/talabat.png', alt: 'Talabat' }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/emaar-properties.png',
                    alt: 'Emaar Properties'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/expo-2020-dubai-uae.png',
                    alt: 'EXPO 2020 Dubai UAE'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/gems-rewards.png',
                    alt: 'GEMS Rewards'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/itp-media-group.png',
                    alt: 'ITP Media Group'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/mskinsey-and-company.png',
                    alt: 'McKinsey &amp; Company'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/carrefour.png',
                    alt: 'Carrefour'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/nakheel-pjsc.png',
                    alt: 'Nakheel PJSC'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/provis-estate-management.png',
                    alt: 'Provis Estate Management'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/abudhabi-global-market.png',
                    alt: 'Abu Dhabi Global Market'
                  }}
                />
              </div>
            </div>

            <div className='pb-6 w-1/4 mx-auto'>
              <div className='px-2 w-full mx-auto block'>
                <FixSizeLogo
                  {...{
                    path: '/logos/abudhabi-national-oil-company.png',
                    alt: 'Abu Dhabi National Oil Company'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
