import { useRef } from 'react';

import { SectionHeading } from 'components/atomic/atoms';
import { CardListMobile } from 'components/atomic/molecules';
import { computeScroll } from 'helpers/computeScroll';

export default function HaflaBlogs({ posts = [] }) {
  const scrollRef = useRef(null);
  const progressBarRef = useRef(null);

  const handleScroll = () => {
    progressBarRef.current.style.width = computeScroll(scrollRef, 20);
  };
  const ProgressBar = () => (
    <div className='w-14 bg-dim-gray h-0.5 mx-auto mt-3 mb-4'>
      <div
        className={'bg-control h-0.5 w-1/5'}
        ref={progressBarRef}
      ></div>
    </div>
  );

  return (
    <section id='blog-section'>
      <div className='bg-platinum pt-4 pb-10'>
        <SectionHeading title='Hafla Blog' />
        <ProgressBar />
        <CardListMobile
          {...{
            cardList: posts,
            handleScroll,
            imageBasePath: '',
            isViewButtonVisible: true,
            scrollRef,
            viewButtonLink: '/blog'
          }}
        />
      </div>
    </section>
  );
}
