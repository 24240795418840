const featuredCategories = [
  {
    categoryId: '53182929-f847-4595-9986-2631466c79cd',
    href: '/categories/event-equipment/event-furniture',
    id: 'e5e6ed7b-c47a-44f5-9c67-d5469cf33795',
    isActive: 'True',
    logoUrl: '/featured-categories/event-furniture.svg',
    name: 'Event Furniture',
    sortOrder: 1
  },
  {
    categoryId: '2633ba37-7dc2-4665-8d3c-63263617117c',
    href: '/categories/event-equipment/bouncy-and-games',
    id: 'b69cbd47-216f-40a0-80a8-5ddbacb65309',
    isActive: 'True',
    logoUrl: '/featured-categories/bouncy-castle.svg',
    name: 'Bouncy Castles',
    sortOrder: 2
  },
  {
    categoryId: 'a9219f8e-75c2-48c8-93a1-ef2917c67d3e',
    href: '/categories/people-and-services/kids-entertainment',
    id: '9a756827-44cd-45a2-a1a8-d1f2450d597a',
    isActive: 'True',
    logoUrl: '/featured-categories/kids-entertainment.svg',
    name: 'Kids Entertainment',
    sortOrder: 3
  },
  {
    categoryId: '8f9390fb-4fe5-4fe9-836e-2f71518b90dc',
    href: '/categories/event-equipment/event-decor-and-accessories/basic-balloon-decor',
    id: '628dd2e8-a1d1-4dbc-9722-f6b08289c47b',
    isActive: 'True',
    logoUrl: '/featured-categories/basic-balloon-decor.svg',
    name: 'Balloon Decor',
    sortOrder: 4
  },
  {
    categoryId: 'b2b0b092-4b2b-4bd9-862a-c5649048a909',
    href: '/categories/people-and-services',
    id: 'cc8851e6-62bd-4ec5-9fe0-f8b1c460a0b4',
    isActive: 'True',
    logoUrl: '/featured-categories/people-and-services.svg',
    name: 'People & Services',
    sortOrder: 5
  },
  {
    categoryId: 'd0fbe34e-8585-4adf-b6a8-3c2555c2cf10',
    href: 'https://book.hafla.com/pages/food-and-beverage',
    id: '949a1ca6-dfd9-4321-8eeb-9a16b4b9df21',
    isActive: 'True',
    logoUrl: '/featured-categories/food-and-beverages.svg',
    name: 'Food & Beverages',
    sortOrder: 6
  },
  {
    categoryId: '0d5a2bdf-b875-4222-b366-89820b143be3',
    href: 'https://book.hafla.com/pages/event-venues',
    id: '7e671166-d86c-47b9-bb65-3f68c09ded96',
    isActive: 'True',
    logoUrl: '/featured-categories/venues.svg',
    name: 'Venues',
    sortOrder: 7
  }
];

export default featuredCategories;
