import { Container, Icon, SectionHeading } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';
import clients from 'config/homepage/clients.json';

export default function LoveOurClients() {
  const RenderImage = () =>
    clients.images.map((item, index) => (
      <Icon
        key={index}
        className='mb-15'
        alt={item.alt}
        url={`${staticMediaStoreBaseURL}/clients/${item.name}`}
      />
    ));

  return (
    <div className='py-10 px-5 md:px-10 bg-white'>
      <div className='block mb-2.5'>
        <SectionHeading
          title='We love our clients'
          subTitle={'and they love us too!'}
          underline
        />
        <Container className={`grid grid-flow-row  grid-cols-${4}`}>
          <RenderImage />
        </Container>
      </div>
    </div>
  );
}
