import config from 'config';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { RibbonButton, Text } from '@/components/atomic/atoms';
import AboutDescription from 'components/homePage/web/AboutSection/AboutDescription';
import { staticMediaStoreBaseURL } from 'config/common';

const BottomContent = () => (
  <div className='grid-container-about-section flex flex-row items-center w-full justify-around'>
    <Text
      className='about-bottom-text'
      content='Your Event is just a click away !'
    />
    <a
      href={config.aboutWhatsappLink}
      target='_blank'
      className='py-2 rounded-full bg-white px-15 text-black text-center uppercase cursor-pointer'
      rel='noreferrer'
    >
      Get Started
      <span
        className='text-brand uppercase'
        style={{ fontSize: 19 }}
      >
        →
      </span>
    </a>
  </div>
);

const BottomSection = () => (
  <div className='-mt-16 -mb-2 overlay'>
    <div className='thumbnail home-background-overlay'>
      <Image
        width={1850}
        height={390}
        layout='fixed'
        alt='Mountains'
        src={`${staticMediaStoreBaseURL}/banners/your_event.jpg`}
        className='block'
      />
      <div className='overlay'></div>
      <BottomContent />
    </div>
  </div>
);
// TODO: pass data with props notation
const AboutContext = () => (
  <Text
    className='about-hafla-text mt-5'
    content='Hafla’s tech platform is bringing together event planners, venues, equipment
  rental companies, caterers, artists, and a long tail of service providers to
  transact seamlessly.'
  />
);

const AboutSectionHafla = ({ router }) => (
  <div className='row-span-3'>
    <div className='flex items-center justify-center space-x-8'>
      <div className='circle-container z-10'>
        <div className='circle-main'>
          <div className='circle-text-container'>
            <Text
              className='font-about'
              content='About hafla'
            />
            <div>
              <hr className='about-title-hr-border' />
            </div>
            <Text
              className='about-text mt-7'
              content='a one-stop-shop for event Organizers to discover, book, and pay
            for everything they need for an event'
            />
            <AboutContext />
            <div className='flex justify-center mt-5'>
              <RibbonButton
                className='rounded-full uppercase px-8 py-2 tracking-wider m-8 ml-10'
                onClick={() => router.push('/about')}
              >
                Learn more
                <span style={{ fontSize: 19 }}> →</span>
              </RibbonButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AboutSection = () => {
  const Router = useRouter();
  return (
    <div className='about-section-main'>
      <div className='grid grid-rows-2 grid-flow-col gap-1'>
        <AboutSectionHafla router={Router} />
        <AboutDescription />
      </div>
      <BottomSection />
    </div>
  );
};

export default AboutSection;
