const eventGlimpsesList = [
  {
    id: 1,
    url: 'https://youtube.com/shorts/jh4J42FFI9Y?feature=share',
    thumbnailUrl: '/event-glimpses/baby-showers-thumb.jpg',
    title: 'Baby Shower'
  },
  {
    id: 2,
    url: 'https://youtube.com/shorts/JpWWaZyjH3Y?feature=share',
    thumbnailUrl: '/event-glimpses/kids-birthdays-thumb.jpg',
    title: 'Kids’ Birthdays'
  },
  {
    id: 3,
    url: 'https://youtube.com/shorts/qN1RBExy-eY?feature=share',
    thumbnailUrl: '/event-glimpses/casual-get-together-thumb.jpg',
    title: 'Casual get-together'
  },
  {
    id: 4,
    url: 'https://youtube.com/shorts/euzFFX5WSG4?feature=share',
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Corporate Events'
  }
];

export default eventGlimpsesList;
