import config from 'config';
import dynamic from 'next/dynamic';
import { Fragment, useState } from 'react';

import MobileNavbar from 'components/Common/MobileNavbar';
import MobileHomeHeader from 'components/Common/header/mobile/MobileHomeHeader';
import { SectionHeading } from 'components/atomic/atoms';
import { BecomePartner, RibbonContainer } from 'components/atomic/molecules';
import CollectionHomeMobile from 'components/homePage/mobile/CollectionHomeMobile';
import CorporateEventsMobile from 'components/homePage/mobile/CorporateEventsMobile';
import EventGlimpsesMobile from 'components/homePage/mobile/EventGlimpsesMobile';
import FeaturedCategories from 'components/homePage/mobile/FeaturedCategories';
import FeaturedEvents from 'components/homePage/mobile/FeaturedEvents';
import HaflaBlogs from 'components/homePage/mobile/HaflaBlogs';
import HomeEventGallery from 'components/homePage/mobile/HomeEventGallery';
import WhyChooseHaflaMobile from 'components/homePage/mobile/WhyChooseHaflaMobile';
import MobileAboutSection from 'components/homePage/mobile/mobile-about-section';
import MobileHomeClientLogo from 'components/homePage/mobile/mobile-home-client-logo';
import Layout from 'components/layout/DefaultLayout';
import corporateEventList from 'data/corporate-events';
import eventGlimpsesList from 'data/event-glimpses';
import featuredEvents from 'data/featured-events';
import haflaServices from 'data/why-choose-hafla';
import { GTM_EVENTS_CTA_NAME } from 'lib/gtm';

const eventGalleryConfig = {
  eventCategory: 'home',
  title: 'homeTitle'
};

const HowDoesItWork = dynamic(
  () => import('components/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const MobileHome = ({ bannerTranslation, data }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  return (
    <Fragment>
      <MobileHomeHeader showSearchModal={showSearchModal} />
      <Layout
        paramData={{
          bannerTranslate: bannerTranslation,
          translate: bannerTranslation,
          eventCategory: 'home',
          homeScreen: true
        }}
      >
        <FeaturedEvents featuredEvents={featuredEvents} />
        <SectionHeading title='browse by' />
        <SectionHeading
          title='category'
          underline
        />
        <FeaturedCategories />
        <RibbonContainer
          {...{
            isEventPlan: true,
            title: 'Premium Event Planning Services',
            buttonTitle: 'Book a free consultation',
            buttonUrl: config.premiumEventPlanningLink,
            ctaName: GTM_EVENTS_CTA_NAME.BOOK_FREE_CONSULTATION
          }}
        />
        <CollectionHomeMobile collections={data?.collections} />
        <CorporateEventsMobile eventList={corporateEventList} />
        <EventGlimpsesMobile eventGlimpsesList={eventGlimpsesList} />
        <WhyChooseHaflaMobile haflaServices={haflaServices} />
        <BecomePartner
          title={'Become a Partner'}
          buttonTitle={'Start Here'}
          buttonUrl='/partners/basic-info'
          subTitle='In 3 easy steps!'
        />
        <HaflaBlogs posts={data?.blogPost} />
        <MobileHomeClientLogo />
        <HowDoesItWork {...{ isMobile: true }} />
        <HomeEventGallery {...{ ...data, ...eventGalleryConfig }} />
        <MobileNavbar
          onPage={'home'}
          showSearchModal={showSearchModal}
          setShowSearchModal={setShowSearchModal}
        />
        <MobileAboutSection />
      </Layout>
    </Fragment>
  );
};

export default MobileHome;
