import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';

const EventIcons = ({ activeFeaturedEventList }) =>
  activeFeaturedEventList.map((event) => {
    const href = event.isExternal ? event.externalURL : `/events/${event.slug}`;
    return (
      <Link
        href={href}
        key={event.id}
      >
        <div className='block w-full mx-auto cursor-pointer'>
          <div className='rounded-md w-15 xxxs:w-full '>
            <Image
              src={`${staticMediaStoreBaseURL}${event.logoUrl}`}
              alt={event.name}
              height={15}
              width={15}
              layout='responsive'
              className='w-full'
            />
          </div>

          <div className='max-w-18 mx-auto block'>
            <p
              className='font-Inter font-normal text-11px basic:text-xs leading-11px
    basic:leading-3 text-nero block mt-2 text-center'
            >
              {' '}
              {event.name}{' '}
            </p>
          </div>
        </div>
      </Link>
    );
  });

export default function FeaturedEvents({ featuredEvents }) {
  const activeFeaturedEventList = featuredEvents.filter(
    ({ isActive }) => isActive
  );
  return (
    <section id='topevent-section'>
      <div className='px-3 xxxs:px-5 pt-1 pb-7'>
        <div className='pt-3 pb-5'></div>
        <div className='grid grid-cols-4 grid-flow-col gap-x-3'>
          <EventIcons {...{ activeFeaturedEventList }} />
        </div>
      </div>
    </section>
  );
}
