import config from 'config';

import {
  IconMobile,
  RibbonButton,
  SectionHeading
} from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat px-5'>
    <div
      className='self-center leading-5 text-lg'
      style={{ lineHeight: '23px' }}
    >
      <div className='font-extrabold'>Find everything you need</div>
      <div className='font-extrabold'>to make your events a success!</div>
      <div>
        Whether it’s event equipment rental or corporate event supplies,
      </div>
      <div>enjoy end-to-end solutions from ideas all the way to execution.</div>
    </div>
  </div>
);

const WhyChooseHaflaMobile = ({ haflaServices = [] }) => (
  <div className='mt-10 text-center'>
    <SectionHeading
      title='WHY CHOOSE HAFLA'
      underline
    />
    <BottomHeader />
    <div className='flex flex-row flex-wrap items-start w-full mt-5 mb-5 justify-evenly'>
      {haflaServices.map(({ id, name, image }) => (
        <IconMobile
          key={id}
          {...{
            title: name,
            className: 'img-center h-20 w-20',
            alt: name,
            url: `${staticMediaStoreBaseURL}${image}`
          }}
        />
      ))}
    </div>
    <div className='flex items-center justify-center mb-10'>
      <div className='w-2/3'>
        <RibbonButton
          style={{
            letterSpacing: 1.7,
            marginLeft: 0,
            background:
              'linear-gradient(50deg, rgb(220, 124, 10) 7.01%, rgb(202, 87, 121) 56.57%, rgb(205, 76, 91) 100.37%)'
          }}
          className='rounded-full uppercase tracking-wider ml-0 font-google-basic font-normal h-11 w-full'
          onClick={() => window.open(config.whyChooseHaflaLink, '_blank')}
        >
          PLAN YOUR EVENT
          <span style={{ fontSize: 15, fontFamily: 'google-basic' }}> →</span>
        </RibbonButton>
      </div>
    </div>
  </div>
);

export default WhyChooseHaflaMobile;
