import { useRef } from 'react';

import { SectionHeading } from 'components/atomic/atoms';
import { CardListMobile } from 'components/atomic/molecules';
import { staticMediaStoreBaseURL } from 'config/common';
import { computeScroll } from 'helpers/computeScroll';

export default function CorporateEventsMobile({ eventList = [] }) {
  const scrollRef = useRef(null);
  const progressBarRef = useRef(null);

  const handleScroll = () => {
    progressBarRef.current.style.width = computeScroll(scrollRef, 20);
  };

  const ProgressBar = () => (
    <div className='w-14 bg-dim-gray h-0.5 mx-auto mt-3 mb-4'>
      <div
        className={'bg-control h-0.5 w-1/5'}
        ref={progressBarRef}
      ></div>
    </div>
  );

  return (
    <section id='collection-section'>
      <div className='bg-platinum pt-4 pb-8 pr-4'>
        <div className='pr-10 pl-10'>
          <SectionHeading
            title='CORPORATE EVENTS SIMPLIFIED'
            subTitle={'Book everything you need \n for your business events.'}
          />
        </div>
        <ProgressBar />
        <CardListMobile
          {...{
            cardList: eventList,
            scrollRef,
            handleScroll,
            isViewButtonVisible: false,
            imageBasePath: `${staticMediaStoreBaseURL}`
          }}
        />
      </div>
    </section>
  );
}
