import config from 'config';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { RibbonButton } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from 'config/common';

const secondTitles = [
  'Hafla’s tech platform is bringing',
  'together event planners, venues,',
  'equipment rental companies,caterers',
  'artists,and a long tail of service',
  'providers to transact seamlessly'
];

const AboutHaflaFooterContent = () => (
  <div
    className='rounded-full text-center text-black
flex bg-white font-Montserrat font-black w-40 p-2'
    style={{ margin: 'auto' }}
  >
    <span
      onClick={() => window.open(config.aboutWhatsappLink, '_blank')}
      className='text-xs font-normal font-medium ml-5'
    >
      GET STARTED
    </span>
    <div className='relative m-1 flex'>
      <Image
        src={`${staticMediaStoreBaseURL}/icons/right-angle.svg`}
        height={5}
        width={8}
        alt='arrow'
      />
    </div>
  </div>
);

const AboutHaflaFooter = () => (
  <div className='about-hafla-footer bg-no-repeat bg-cover'>
    <div
      className='opacity-100 h-full w-full
margin-auto text-center py-5 mt-5'
    >
      <div
        className='margin-auto text-white font-Montserrat text-xl
font-black mb-2'
      >
        Your event is just a click away!
      </div>
      <AboutHaflaFooterContent />
    </div>
  </div>
);

const CommonTextSection = ({ imageUrl, lineItems = [] }) => (
  <div className='align-top flex font-medium text-sm m-auto w-80'>
    <Image
      src={imageUrl}
      alt='About Hafla'
      height={40}
      width={25}
      objectFit='initial'
    />
    <ul className='list-disc ml-5'>
      {lineItems.map((lineItem, index) => (
        <div key={index}>{lineItem}</div>
      ))}
    </ul>
  </div>
);

const TopSectionAboutHafla = () => (
  <div className='row-span-3'>
    <div className='flex items-center justify-center mx-auto space-x-8'>
      <div>
        <div className='font-about mt-10 font-medium mb-3'>About hafla</div>
        <div className='items-center text-center px-10'>
          <hr className='about-title-hr-border-mobile' />
        </div>
        <div className='about-text mt-7 w-80 font-medium'>
          a one-stop-shop for event Organizers to discover, book, and pay for
          everything they need for an event
        </div>
        <div className='about-hafla-text mt-5 w-80'>
          <ul className='list-disc font-medium'>
            {secondTitles.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </ul>
        </div>
        <div />
      </div>
    </div>
  </div>
);

const MobileAboutSection = () => {
  const Router = useRouter();
  return (
    <div className='bg-white'>
      <div>
        <TopSectionAboutHafla />
        <div className='row-span-2 mt-7 px-5 font-medium'>
          <CommonTextSection
            {...{
              imageUrl: `${staticMediaStoreBaseURL}/icons/digit-one-image.svg`,
              lineItems: [
                'Hafla delivers an e-commerce-like,',
                'easy,fast,and trusted experience in ',
                'an otherwise low-tech,high',
                'fragmentation,high friction industry'
              ]
            }}
          />
          <div className='flex my-5'>
            <hr className='about-delivery-start-hr-mobile mx-10' />
            <hr className='about-delivery-end-hr-mobile mx-10' />
          </div>
          <CommonTextSection
            {...{
              imageUrl: `${staticMediaStoreBaseURL}/icons/digit-two-image.svg`,
              lineItems: [
                'Hafla is for events what Expedia is ',
                'for travel-aggregating and ',
                'digitizing a $1Tn+ industry'
              ]
            }}
          />
          <div className='flex py-5'>
            <hr className='about-event-hr-mobile ml-10' />
          </div>
          <CommonTextSection
            {...{
              imageUrl: `${staticMediaStoreBaseURL}/icons/digit-three-image.svg`,
              lineItems: [
                'Hafla minimizes the stress in event',
                ' planning so that everyone can ',
                'celebrate more'
              ]
            }}
          />
        </div>
      </div>
      <div className='text-center'>
        <RibbonButton
          className='text-sm font-normal py-2 px-5 rounded-full  my-5'
          onClick={() => Router.push('/about')}
        >
          LEARN MORE
          <span style={{ fontSize: 19 }}> →</span>
        </RibbonButton>
      </div>
      <AboutHaflaFooter />
    </div>
  );
};

export default MobileAboutSection;
