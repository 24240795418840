const corporateEventList = [
  {
    id: 1,
    image: 'corp-1.svg',
    bannerImageUrl: '/events/corporate-event/corp-1.svg',
    name: 'Catering & Entertainment'
  },
  {
    id: 2,
    image: 'corp-2.jpg',
    bannerImageUrl: '/events/corporate-event/corp-2.jpg',
    name: 'Equipment & Furniture'
  },
  {
    id: 3,
    image: 'corp-3.svg',
    bannerImageUrl: '/events/corporate-event/corp-3.svg',
    name: 'Decorations & Setup'
  }
];

export default corporateEventList;
